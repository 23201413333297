import Swiper, { Mousewheel } from "swiper";
Swiper.use([Mousewheel]);

export default function accordeonSlider() {
  const wrapper = document.querySelector(".accordeon_swiper");
  if (!wrapper) return;

  const point = 768;
  let isMobile = window.innerWidth <= point;

  const checkResponsive = (e) => {
    isMobile = window.innerWidth <= point;

    isMobile ? e.enable() : e.disable();

    e.slides.forEach((s) => s.classList.toggle("active", isMobile));
  };

  const slider = new Swiper(wrapper, {
    slidesPerView: "auto",
    speed: 1000,
    on: {
      init: function (e) {
        e.slideTo(1, 0);
        e.disable();
        checkResponsive(e);
      },
    },
    mousewheel: {
      forceToAxis: true,
    },
    breakpoints: {
      768: {},
    },
  });
  slider.on("breakpoint", checkResponsive);
}
