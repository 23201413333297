import gsap from "gsap";

export default function cookiesPopup() {

  const cookiesPopup = document.getElementById("cookiesPopUp");
  const accept = document.getElementById("cookiesButton");

  const tl = gsap.timeline({ paused: true, delay: 2.5 })
    .to(cookiesPopup, { duration: 0.25, autoAlpha: 1, ease: "none" });

  const closeCookies = () => {
    sessionStorage.cookiesAccepted = "true";
    tl.reverse();
  };

  if (!sessionStorage.cookiesAccepted) {
    tl.play();
  }

  accept.addEventListener("click", closeCookies);
}
