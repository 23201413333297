const accordeon = () => {
  const wrapper = document.querySelector(".accordeon-tabs");
  if (!wrapper || window.innerWidth <= 768) return;

  const tabs = [...wrapper.querySelectorAll(".tab")];

  const hoverTab = (hovered) => {
    tabs.forEach((tab, idx) => {
      tab.classList.toggle("active", hovered === idx);
    });
  };

  const unHoverTab = (hovered) => {
    tabs[hovered].classList.remove("active");
  };

  // tabs[0].classList.add("active");
  tabs.forEach((tab, idx) => {
    tab.addEventListener("mouseover", () => hoverTab(idx));
    tab.addEventListener("mouseleave", () => unHoverTab(idx));
  });
};

export default accordeon;
