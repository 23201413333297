import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import LocomotiveScroll from 'locomotive-scroll';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const scroller = document.querySelector('main');

// Default Scrolltrigger options
ScrollTrigger.defaults({
  scroller: scroller,
});


// Scrolling wrapper scrollbar
const locoScroll = new LocomotiveScroll({
  el: scroller,
  smooth: true,
  multiplier: 0.75,
  // reloadOnContextChange: true,
  touchMultiplier: 1,
  tablet: {
    breakpoint: 1024
  }
});

// each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
locoScroll.on("scroll", ScrollTrigger.update);

// Scrolltriger proxy
ScrollTrigger.scrollerProxy(scroller, {
  scrollTop(value) {
    return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
  }, // we don't have to define a scrollLeft because we're only scrolling vertically.
  getBoundingClientRect() {
    return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
  },
});

// Update LocomotiveScroll each time ScrollTrigger refreshes
ScrollTrigger.addEventListener("refresh", () => {
  locoScroll.update();
});

// after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
window.addEventListener('load', () => {
  ScrollTrigger.refresh(true);
});


window.addEventListener('deviceorientation', () => {
  ScrollTrigger.refresh(true);
})

// Update locoScroll when window was resized
window.addEventListener('resize', () => {
  ScrollTrigger.refresh(true);
})

// Disable / enable scroll
const stopScroll = () => {
  locoScroll.stop();
  disableBodyScroll(document.body);
};

const startScroll = () => {
  locoScroll.start();
  enableBodyScroll(document.body);
};


export { gsap, ScrollTrigger, locoScroll, stopScroll, startScroll };