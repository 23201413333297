const copyToClipboard = () => {
  const button = document.querySelector("[data-copy]");
  if (!button) return;

  const target = document.querySelector(`#${button.dataset.copyTarget}`);
  const tip = button.querySelector(".copy-title");
  button.addEventListener("click", () => {
    target.select();
    target.setSelectionRange(0, 99999);

    navigator.clipboard
      ? navigator.clipboard.writeText(target.value)
      : document.execCommand("copy");

    target.blur();
    tip.classList.add("active");

    setTimeout(() => tip.classList.remove("active"), 1000);
  });
};

export default copyToClipboard;
