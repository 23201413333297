import Swiper, { FreeMode, Mousewheel } from "swiper";
Swiper.use([FreeMode, Mousewheel]);

export default function raritySlider() {
  const wrapper = document.querySelector(".nft_rarity_swiper");
  if (!wrapper) return;

  const point = 1024;

  const checkResponsive = (e) => {
    let isMobile = window.innerWidth <= point;
    console.log(isMobile);

    isMobile ? e.enable() : e.disable();
  };

  const slider = new Swiper(wrapper, {
    slidesPerView: "auto",
    grabCursor: true,
    speed: 1000,
    freeMode: true,
    on: {
      init: (e) => {
        e.disable();
        checkResponsive(e);
      },
    },
    mousewheel: {
      forceToAxis: true,
    },
    breakpoints: {
      1024: {},
    },
  });
  slider.on("breakpoint", checkResponsive);
}
