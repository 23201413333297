import { ScrollTrigger, locoScroll } from "../utilities/scroll-config";

const articleNavigation = () => {
  const article = document.querySelector('.scrolled-blocks');
  const navigation = article ? article.querySelector('.navigation') : false;

  if(!navigation || !article) return;

  const content = article.querySelector('.content');
  const navs = [...navigation.querySelectorAll('.nav_button')];
  const rows = [...article.querySelectorAll('.content .column')];
  const header = document.querySelector('header');
  const footer = document.querySelector('footer');


  const offset = header.clientHeight;
  const contentHeight = content.clientHeight;

  const scrollToActive = (clicked) => {
    locoScroll.scrollTo(rows[clicked], { offset: `-${offset*0.9}` });
  }

  navs.forEach((button, idx) => {
    button.classList.toggle('active', idx === 0);
    button.addEventListener('click', () => scrollToActive(idx));
  });

  // Pin navigation on desktop
  ScrollTrigger.matchMedia({
    "(min-width: 1025px)": () => {

      ScrollTrigger.create({
        trigger: navigation,
        pin: true,
        start: `top ${offset}px`,
        endTrigger: footer,
        end: 'top bottom',
      });

      rows.forEach((row, idx) => {

        ScrollTrigger.create({
          trigger: row,
          start: `top ${offset}px`,
          end: `+=${row.offsetHeight}`,
          onToggle: (e) => navs[idx].classList.toggle('active', e.isActive),
        });
      });
    }
  });


}

export default articleNavigation;