import Swiper, { Mousewheel, FreeMode } from "swiper";
Swiper.use([Mousewheel, FreeMode]);

export default function cardsSlider() {
  const wrapper = document.querySelector(".brands-customers .cards.swiper");
  if (!wrapper) return;

  const point = 768;
  let isMobile = window.innerWidth <= point;

  const checkResponsive = (e) => {
    isMobile = window.innerWidth <= point;
    isMobile ? e.enable() : e.disable();
    // isMobile && e.slideTo(1, 0);
  };

  const slider = new Swiper(wrapper, {
    slidesPerView: "auto",
    speed: 1000,
    on: {
      init: function (e) {
        e.slideTo(1, 0);
        e.disable();
        checkResponsive(e);
      },
    },
    mousewheel: {
      forceToAxis: true,
    },
    breakpoints: {
      768: {},
    },
  });
  slider.on("breakpoint", checkResponsive);
}
