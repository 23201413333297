import Swiper, { FreeMode, Mousewheel } from "swiper";
Swiper.use([FreeMode, Mousewheel]);

export default function roadmapSlider() {
  const wrapper = document.querySelector(".roadmap-slider");
  if (!wrapper) return;

  const slider = new Swiper(wrapper, {
    slidesPerView: "auto",
    grabCursor: true,
    speed: 1000,
    freeMode: true,
    mousewheel: {
      forceToAxis: true
    },
  });
}
