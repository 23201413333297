import { startScroll, stopScroll } from "../utilities/scroll-config";

export default function toggleMenu() {
  const header = document.querySelector('header');
  const burger = header.querySelector('.mobile-menu__burger');

  const openMenu = () => {
    stopScroll();
    header.classList.add('active-menu');
  }
  const closeMenu = () => {
    startScroll();
    header.classList.remove('active-menu');
  }

  burger.addEventListener('click', () => {
   !header.classList.contains('active-menu') ? openMenu() : closeMenu();
  });

};
