import Swiper, { Mousewheel } from "swiper";
Swiper.use([Mousewheel]);

export default function blogSlider() {
  const wrapper = document.getElementById("blogSlider");
  if (!wrapper) return;

  const cont = wrapper.querySelector(".swiper-wrapper");
  const slides = cont.querySelectorAll(".swiper-slide");
  const speed = 300;
  const c = (i) => slides[i].cloneNode(true);

  cont.append(c(0), c(1));
  cont.prepend(c(slides.length - 2), c(slides.length - 1));

  const slider = new Swiper(wrapper, {
    slidesPerView: "auto",
    // loop: true,
    centeredSlides: true,
    grabCursor: true,
    followFinger: false,
    touchRatio: 0.4,

    mousewheel: {
      forceToAxis: true,
    },
    on: {
      init: function (s) {
        s.slideTo(2, 0);
      },
      slideChangeTransitionEnd: function (s) {
        let length = s.slides.length;
        let progress = (1 / (s.slides.length - 1)).toFixed(5);
        let last = ((1 / (s.slides.length - 1)) * (length - 2)).toFixed(5);
        let p = s.progress.toFixed(5);
        setTimeout(() => {
          if (p == last || p == progress) {
            p == last && s.slideTo(2, 0);
            p == progress && s.slideTo(length - 3, 0);
          }
        }, 1);
      },
      transitionEnd: function (s) {
        s.slides.forEach((s) => transitionIcon(s, 0));
      },
      touchEnd: function (s) {
        s.slides.forEach((s) => transitionIcon(s, speed));
      },
    },
  });

  function transitionIcon(s, t) {
    let i = s.querySelector(".card");
    i.style.transitionDuration = t + "ms";
  }
}
