import Swiper, { Autoplay, FreeMode, Mousewheel } from 'swiper';
Swiper.use([Autoplay, FreeMode, Mousewheel]);

export default function partnerSlider() {

  const wrapper = document.querySelector('.partners-slider');
  if (!wrapper) return;

  const slider = new Swiper(wrapper, {
    slidesPerView: 'auto',
    loop: true,
    centeredSlides: true,
    grabCursor: true,
    // longSwipes: false,
    speed: 5000,
    autoplay: {
      delay: 0,
    },
    mousewheel: {
      forceToAxis: true
    }
    // freeMode: {
    //   enabled: true,
    //   // momentum: false,
    //   // momentumBounce: false,
    // },
  });

};
