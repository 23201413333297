import { gsap, ScrollTrigger } from "../utilities/scroll-config";

export default function nftSlider() {
  const wrapper = document.getElementById('toggleNFT');
  if (!wrapper) return;

  const nfts = [...wrapper.querySelectorAll('.nft')];
  const desc = wrapper.querySelector('.mobile-title');
  const descTitle = desc.querySelector('h3');
  const descText = desc.querySelector('p');
  const descSubtitle = desc.querySelector('h4');
  let num = 1;

  const togglePointer = (active) => {
    nfts.forEach((nft, idx) => {
      const icon = nft.querySelector('.ico.lg');
      icon.classList.toggle('active', active === idx);
    });
  }

  const toggleDescription = () => {

    let activeNFT = nfts[num];

    const title = activeNFT.querySelector('.desc h3').innerHTML;
    const text = activeNFT.querySelector('.desc p').innerHTML;
    const subtitle = activeNFT.querySelector('.desc h4').innerHTML;

    descTitle.innerHTML = title;
    descText.innerHTML = text;
    descSubtitle.innerHTML = subtitle;

    togglePointer(num);
  }

  const autoToggle = gsap.timeline({ paused: true, repeat: -1, repeatDelay: 2 })
    .to(desc, { duration: 0.5, autoAlpha: 0, ease: "none" })
    .call(()=> {
      num = (num < 2) ? num + 1 : 0;
      toggleDescription();
    })
    .to(desc, { duration: 0.5, autoAlpha: 1, ease: "none" })

  const toggleNFT = () => {
    nfts.forEach((nft, idx) => {
      // const icon = nft.querySelector('.ico.lg');

      nft.addEventListener('click', () => {
        autoToggle.clear();
        num = idx;
        togglePointer(idx);
        gsap.timeline()
          .to(desc, { duration: 0.5, autoAlpha: 0, ease: "none" })
          .call(toggleDescription)
          .to(desc, { duration: 0.5, autoAlpha: 1, ease: "none" })
      });
    });
  }

  ScrollTrigger.matchMedia({
    "(max-width: 768px)": () => {

      toggleNFT();

      ScrollTrigger.create({
        trigger: wrapper,
        onToggle: e => e.isActive ? autoToggle.play() : autoToggle.pause()
      });
    }
  });

};
