import { locoScroll, ScrollTrigger } from "../utilities/scroll-config";

const accordeonList = () => {
  const accordeons = [...document.querySelectorAll(".accordeon-panels")];
  const isDesktop = window.innerWidth > 1024;
  if (!accordeons.length) return;

  accordeons.forEach((a) => {
    const action = a.dataset.action;

    if (action === "click") {
      toggleOnClick(a);
    }

    if (action === "hover" && isDesktop) {
      toggleOnHover(a);
    }
  });

  function toggleOnClick(accordeon) {
    var buttons = [...accordeon.querySelectorAll("button")];
    if (!buttons.length) return;

    const toggleTabs = (clicked) => {
      buttons.forEach((button, idx) => {
        let panel = button.nextElementSibling;
        panel.style.maxHeight = idx === clicked ? panel.scrollHeight + "px" : 0;
        button.classList.toggle("active", idx === clicked);
      });
    };

    buttons.forEach((button, idx) => {
      button.addEventListener("click", () => {
        let panel = button.nextElementSibling;

        if (button.classList.contains("active")) {
          button.classList.remove("active");
          panel.style.maxHeight = 0;
        } else {
          button.classList.add("active");
          toggleTabs(idx);
        }

        setTimeout(() => {
          ScrollTrigger.refresh(true);
        }, 200);
      });
    });
  }

  function toggleOnHover(accordeon) {
    var triggers = [...accordeon.querySelectorAll(".trigger")];
    if (!triggers.length) return;

    const showPanel = (trigger) => {
      let panel = trigger.querySelector(".panel");
      if (!panel) return;

      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
      ScrollTrigger.refresh(true);
    };

    const hidePanel = (trigger) => {
      let panel = trigger.querySelector(".panel");
      if (panel) {
        panel.style.maxHeight = null;
        ScrollTrigger.refresh(true);
      }
    };

    triggers.forEach((trigger) => {
      trigger.addEventListener("pointerenter", () => {
        showPanel(trigger);
      });

      trigger.addEventListener("pointerleave", () => {
        hidePanel(trigger);
      });
    });
  }
};

export default accordeonList;
