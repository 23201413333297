import { gsap } from "gsap/all";

function mouseMoveParallax() {

  let wrapper = document.querySelector('.parallax-wrapper');
  if (!wrapper) return;
  let parallaxItems = [...wrapper.querySelectorAll('[data-parallax]')];
  if (!parallaxItems.length) return;

  let fixer = -0.05;
  let centerX = window.innerWidth / 2;
  let centerY = window.innerHeight / 2;
  let pageX, pageY;
  let offset = [];

  parallaxItems.forEach(el => {
    offset.push(JSON.parse(el.dataset.parallax));
  });

  wrapper.addEventListener('mousemove', e => {

    pageX = (e.pageX - centerX) * fixer;
    pageY = (e.pageY - centerY) * fixer;

    parallaxItems.forEach((item, idx) => {

      let speedX = offset[idx]?.x;
      let speedY = offset[idx]?.y;

      gsap.to(item, {
        x: (pageX * speedX),
        y: (pageY * speedY)
      });
    });
  });

}

export { mouseMoveParallax };