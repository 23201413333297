import gsap from "gsap";

function teamSlider() {
  const slider = document.getElementById("teamSlider");
  if (!slider) return;

  const info = slider.querySelector(".description");
  const cont = slider.querySelector(".icons");
  const name = info.querySelector(".name");
  const desc = info.querySelector(".desc");

  const toggleClass = () => {
    const icons = cont.children;
    let icon_clone = icons[0];
    icons[0].remove();
    cont.append(icon_clone);

    info.classList.add("hide");

    setTimeout(() => {
      name.innerHTML = icons[1].dataset.name;
      desc.innerHTML = icons[1].dataset.desc;
      info.classList.remove("hide");
    }, 250);
  };

  cont.classList.add('started');

  gsap.to({}, { duration: 5, onRepeat: toggleClass, repeat: -1 });
}

export default teamSlider;
