import { gsap, startScroll, stopScroll } from "../utilities/scroll-config";

const videoPlayer = () => {
  const openButton = document.querySelector('.open-player');
  if (!openButton) return;

  const thumbVideo = openButton.querySelector('video');
  const videoPopup = document.querySelector('#videoPopup');
  const playerVideo = videoPopup.querySelector('video');
  const closeButton = videoPopup.querySelector('.close');
  let startTime = 0.5;

  const startVideo = (video) => {
    if (video) {
      video.play();
    }
  }

  const stopVideo = (video) => {
    if (video) {
      video.pause();
      video.currentTime = startTime;
    }
  }

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => startVideo(playerVideo),
    onReverseComplete: () => stopVideo(playerVideo)
  }).to(videoPopup, { duration: 0.5, autoAlpha: 1 });

  const openPopup = () => {
    stopScroll();
    tl.play();
  }
  const closePopup = () => {
    startScroll();
    tl.reverse();
  }

  openButton.addEventListener('mouseenter', () => {
    startVideo(thumbVideo);
  });

  openButton.addEventListener('mouseleave', () => {
    stopVideo(thumbVideo);
  });

  openButton.addEventListener('click', openPopup);
  closeButton.addEventListener('click', closePopup);
}


export default videoPlayer;
