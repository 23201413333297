// Detect webp support
import "webp-in-css/polyfill";

// Detect browser
import { detect } from "detect-browser";

// Add browser to html class
const browser = detect();
document
  .getElementsByTagName("html")[0]
  .classList.add(browser ? browser.name : "");