import autosize from "autosize";
import {
  ScrollTrigger,
  startScroll,
  stopScroll,
} from "../utilities/scroll-config";

export default function Form() {
  const forms = document.querySelectorAll("form");
  if (!forms.length) return;

  const popUps = document.querySelectorAll(".pop-up");
  const successPopUp = [...popUps].find((p) => p.id == "pop-up");
  const waitlistPopUp = [...popUps].find((p) => p.classList.contains("wl"));
  const walletPopUp = [...popUps].find((p) => p.classList.contains("wlt"));
  const closePopUp = document.querySelectorAll("[data-close]");
  const waitlistButton = document.querySelectorAll("[data-waitlist-pop-up]");
  const walletButton = document.querySelectorAll("[data-wallet-pop-up]");
  const textarea = [...document.querySelectorAll("textarea")];

  autosize(textarea);

  // Update scroll height on textarea height change
  textarea.length &&
    textarea.forEach((t) => {
      t.addEventListener("autosize:resized", function () {
        ScrollTrigger.refresh(true);
      });
    });

  function createPreloader(form) {
    const button = form.querySelector(".submit_row");

    let p = document.createElement("div");
    let l = document.createElement("div");
    p.classList.add("f_preloader");
    l.classList.add("loader");
    p.append(l);
    button.append(p);
  }

  // Form functions
  function useForm(form) {
    const method = form.method;

    createPreloader(form);

    // console.log(BAMBOO, form.id);
    const inputs = [...form.elements].filter(
      (i) => i.type !== "submit" && i.type !== "hidden"
    );
    const submit = [...form.elements].filter((i) => i.type === "submit")[0];
    const labels = inputs.map((i) => i.closest("label")).filter((i) => i);
    // const submitText = submit.innerHTML;
    let isFetching = false;

    // Before request
    const beforeSend = () => {
      // Remove class 'error' from label
      labels.forEach((label) => label.classList.remove("error"));

      // Lock submit button to prevent multiple clicks
      submit.disabled = true;
      // submit.innerText = "Please wait...";
      submit.classList.add("locked");

      isFetching = true;
    };

    const afterSend = () => {
      // Lock submit button to prevent multiple clicks
      submit.disabled = false;
      // submit.innerHTML = submitText;
      submit.classList.remove("locked");
      isFetching = false;
    };

    // After successful request
    const onSuccess = (message) => {
      //Show message
      successPopUp.querySelector(".message").innerHTML = message.message;

      //Open pop up & Disable Body scroll
      // togglePopUp(false, true);
      showPopUp(successPopUp);

      // Reset form
      inputs.forEach((input) => {
        input.value = "";
        input.setAttribute("value", "");
        input.closest("label").classList.remove("active");
      });

      // Update autosize
      setTimeout((e) => autosize.update(textarea));
    };

    // Form validation error
    const onValidateError = (errors) => {
      errors.forEach((error) => {
        let input = inputs.filter((i) => i.name === error.idref);
        let label = input[0].closest("label");
        label.classList.add("error");
        label.querySelector(".tip").innerHTML = error.message;
      });
    };

    // Server error
    const onServerError = (error) => {
      console.log(error);

      // popUp.classList.add("active", "error");
    };

    // Send form via AJAX
    const sendForm = () => {
      if (isFetching) return;

      // Before request send
      beforeSend();

      // Form data
      let data = new FormData(form);
      let url = `${BAMBOO.baseUrl}/wp-json/contact-form-7/v1/contact-forms/${form.id}/feedback`;

      // Send request
      fetch(url, {
        method: method,
        credentials: "same-origin",
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          afterSend();

          if (data) {
            // On validate errors
            switch (data.status) {
              case "validation_failed":
                onValidateError(data.invalid_fields);
                console.log("validation_failed");
                break;
              case "mail_sent":
                console.log("mail_sent");
                onSuccess(data);
                break;
              case "mail_error":
                console.log("mail_error");
                onServerError(data);
                break;
              default:
                console.log("default");
                onSuccess(data);
            }
          }
        })
        // Fetch error
        .catch((err) => {
          onServerError(err);
        });
    };

    const innerTextToInput = (label, value) => {
      let input = label.querySelector(".hidden");
      input.value = value ? value : "";
    };

    inputs.forEach((input) =>
      input.addEventListener("input", () => {
        let label = input.closest("label");
        let v = input.value;

        input.classList.contains("pseudo") && innerTextToInput(label, v);

        label.classList.toggle("active", v);
      })
    );

    // Submit form on click
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      sendForm();
    });
  }

  function showPopUp(popup) {
    [...popUps].forEach((p) => p.classList.remove("active"));
    popup.classList.add("active");
    stopScroll();
  }

  function hidePopUp() {
    [...popUps].forEach((p) => p.classList.remove("active"));
    startScroll();
  }

  [...forms].forEach((form) => useForm(form));
  [...closePopUp].forEach((b) => b.addEventListener("click", hidePopUp));
  [...waitlistButton].forEach((b) =>
    b.addEventListener("click", (e) => showPopUp(waitlistPopUp))
  );
  [...walletButton].forEach((b) =>
    b.addEventListener("click", (e) => {
      e.preventDefault();
      showPopUp(walletPopUp);
    })
  );
}
